<template>
    <div class="container">
        <div class="swagger-carousel">
            <el-carousel :interval="5000" arrow="always" height="600px">
                <el-carousel-item v-for="(item,index) in banners" :key="index">
                    <img :src="item.pic" class="s-img"/>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="container-box">
            <div class="left-box"></div>
            <div class="logo-box">
                <!-- <router-link to="/index"><img src="../../assets/images/logo.png" width="152" height="35"></router-link> -->
				<router-link to="/index"><img src="../../assets/images/logo-fff.png" height="35" width="152" class="logo" />
				</router-link>
            </div>
            <div class="mid-box"></div>
            <div class="nav-bar-box">
                <div v-for="item in menus" :key="item.menuName" class="nav-btn"
                     :class="item.menuUrl==url?'btn-select':'btn-no-select'">
                    <router-link :to="item.menuUrl">
                        <b>{{item.menuName}}</b>
                    </router-link>
                </div>
                <div class="login-box">
                    <span v-if="!user"><router-link to="/Login">登录</router-link>/<router-link to="/Regist">注册</router-link></span>
                    <span v-if="user">
					<span style="cursor:pointer" @click="myCenter" title="个人中心">{{user.userAccount}} 欢迎您!</span>
					<span style="color: #00a854;padding-left: 1rem" class="loginout-btn" @click="loginout">退出登录</span>
				</span>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
    export default {
        name: "newHeader",
        props: ["url", "type"],
        data: function () {
            return {
                menus: [],
                user: '',
                banners: [{pic: require('@/assets/images/banner/banner3.jpg')},{pic: require('@/assets/images/banner/banner4.jpg')},{pic: require('@/assets/images/banner/banner2.jpg')}, {pic: require('@/assets/images/banner/banner1.jpg')},]
            }
        },
        created() {
            /* this.menus = [
                {
                    menuName: "首页-业务平台",
                    menuUrl: '/index',
                },
                {
                    menuName: "车辆租赁",
                    menuUrl: '/WuliuIndex',
                },
                {
                    menuName: "金融服务",
                    menuUrl: '/JinrongIndex',
                },
                {
                    menuName: "法律服务",
                    menuUrl: '/FalvIndex',
                }, {
                    menuName: "关于我们",
                    menuUrl: '/AboutUs',
                },
            ] */
            // if (this.type && this.type == 1) {
            //     this.menus = [
            //         {
            //             menuName: "项目",
            //             menuUrl: '/Projects',
            //         },
            //         {
            //             menuName: "运力",
            //             menuUrl: '/Transport',
            //         },
            //         {
            //             menuName: "配套设施",
            //             menuUrl: '/Support',
            //         },
            //         {
            //             menuName: "申请流程",
            //             menuUrl: '/Applyflow',
            //         },
            //     ]
            // } else {
            //     this.initMenus();
            // }
			this.initMenus();
            let userinfo = sessionStorage.getItem("userinfo");
            if (userinfo) {
                this.user = JSON.parse(userinfo);
            }
        },
        methods: {
            //初始化菜单列表
            initMenus: function () {
                this.HTTP.get('/index/menus').then(res => {
                    if (res.data.code == 1) {
                        this.menus = res.data.rows;
                    }
                }).catch(err => {
                    console.log(err);
                })
            },
            loginout() {
                this.$confirm('确认退出?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    sessionStorage.removeItem("userinfo");
                    sessionStorage.removeItem("userMsg");
                    sessionStorage.removeItem("token");
                    this.$store.state.token = '';
                    this.user = ''
                    this.$message.success("退出成功")
                    this.$router.push("/index")
                }).catch(() => {
                });
            },
            myCenter: function () {
                this.$router.push("/MyCenter");
            }
        }
    }
</script>

<style lang="stylus" scoped>
    .swagger-carousel {
        width: 90%;
        margin 0 auto;
    }

    .container
        width 100%
        background-color white

    .container-box
        width: 100%;
        height 6rem
        font-family Microsoft YaHei Regular, Microsoft YaHei Regular-Regular
        display flex
        flex-direction row
        flex-wrap nowrap
        justify-content left
        margin 0 auto
        position: absolute;
        top 0
        z-index 99
        background-color rgba(118, 125, 163, 0.62);

    .left-box {
        height 100%
        line-height 6rem
    }


    @media only screen and (min-width: 768px){
        .left-box {
            width: 5%;
        }
    }

    @media only screen and (min-width: 992px){
        .left-box {
            width: 10%;
        }
    }

    @media only screen and (min-width: 1200px){
        .left-box {
            width: 15%;
        }
    }

    @media only screen and (min-width: 1920px){
        .left-box {
            width: 18%;
        }
    }

    @media only screen and (min-width: 768px){
        .mid-box {
            width: 2%;
        }
    }

    @media only screen and (min-width: 992px){
        .mid-box {
            width: 3%;
        }
    }

    @media only screen and (min-width: 1200px){
        .mid-box {
            width: 4%;
        }
    }

    @media only screen and (min-width: 1920px){
        .mid-box {
            width: 5%;
        }
    }

    .mid-box {
        height 100%
        line-height 6rem
    }

    .logo-box
        height 100%
        padding 1rem 1rem
        text-align left
        line-height 6rem
        // background-color #fff

    .login-box
        height 100%
        flex-shrink 2
        padding-right 1rem
        text-align center;
        width 25%;
        height 100%;
        span
            line-height 6rem
            font-size 16px
            font-weight 400
            color #d3dfeb

    .nav-bar-box
        .nav-btn:hover
            background-color #164d8b

            b
                color white

    .nav-bar-box
        width 100%
        height 100%
        display flex
        flex-direction row
        flex-wrap nowrap
        align-items center
        justify-content space-evenly

        .nav-btn
            height 4rem;
            padding 0px 30px;
            border-radius 100px;

            b
                line-height 4rem
                font-size 16px
                font-weight 300

        .btn-select
            background-color #164d8b

            b
                color white

        .btn-no-select {
            background-color transparent
        }

    .btn-no-select a {
        color #cecece
    }
    >>> a{
        color #d3dfeb
    }
    .loginout-btn
        cursor pointer

    @media screen and (min-width: 320px)  and (max-width: 1024px)
        .container-box
            width 100%
    .s-img{
        max-width: 100%;
        height: auto;
        position: relative;
        top: -31%;
    }

</style>
