<template>
    <div class="container">
        <div class="my-swipe-container">
            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#f78927">
                <van-swipe-item v-for="item in pics" :key="item.name">
                    <img :src="item.picUrl" class="swipe-pic">
                </van-swipe-item>
            </van-swipe>
        </div>
    </div>
</template>

<script>
    import { Swipe, SwipeItem } from 'vant';
	import banner1 from '@/assets/images/banner1.png'
	import banner2 from '@/assets/images/banner2.png'
    export default {
        name: "Swipper",
		data:function(){
		  return {
		      pics:[
		          {
		              name:"1",
		              picUrl:banner1,
		              url:''
		          },
		          {
		              name:"2",
		              picUrl:banner2,
		              url:''
		          }
		      ],
			}
		},
        components:{
			banner1,
			banner2,
			[Swipe.name]:Swipe,
			[SwipeItem.name]:SwipeItem,
        },
    }
</script>

<style lang="stylus" scoped>
    .container
        width 100rem
        height 0.4*@width
        margin 0 auto

    .my-swipe-container
        width 100%
        height 100%
        background-color #a4a5a0

    .my-swipe
        width 100%
        height 100%

    .my-swipe >>> .van-swipe-item
        width 100%
        height 100%

    .swipe-pic
        width 100%
        height 100%

@media screen and (min-width 320px)  and (max-width 1024px)
    .container
        width 100%
</style>
